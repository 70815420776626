.custom-image{
    /* background-color: #a783cf; */
    /* background-image: url('./Assets/Images/cart_bg.png'); */
    /* background-position: 100% 100%; */
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    border: 1px solid #ffffff69;
  }
  input[disabled] {
    cursor: not-allowed;
  }
 
  .custom-thumbnail{
    /* background-color: #a783cf !important; */
    /* background-image: url('./Assets/Images/cart_bg.png') !important; */
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
    margin-right: 5px;
  }
  .image-gallery-slide .image-gallery-image{
    /* width:50% !important; */
    border-radius: 10px;
  }
  .image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg{
    height: 50px;
    width: 50px;
  }
  .image-gallery-icon:hover{
    color: #ffffff69;
  }
  .image-gallery-thumbnails .image-gallery-thumbnails-container{
    text-align: start !important;
  }
  .image-gallery-thumbnails {
    margin-top: 10px;
  }
  .image-gallery-thumbnail .image-gallery-thumbnail-image{
    /* width: 60% !important; */
  }
  .image-gallery-thumbnail.active, .image-gallery-thumbnail:hover, .image-gallery-thumbnail:focus{
    border: 3px solid #ffffff69 !important;
  }
  .image-gallery-thumbnail{
    border: 3px solid transparent !important;
  }